<template>
    <div class="health-professional-poll-result relative">
        <button
            class="btn-picto btn-picto--white absolute top-20 lg:top-8 left-1/2 -translate-x-1/2 lg:transform-none lg:left-10 z-20 whitespace-nowrap"
            @click="restartPoll"
        >
            Faire un nouveau test
        </button>
        <div class="max-w-3xl min-h-screen flex flex-col items-center justify-center text-center text-white mx-auto py-32 lg:py-36 px-4">
            <h2 v-html="content.title"/>
            <p class="body-1 mt-8">
                {{ content.text }}
            </p>
            <div class="mt-6 flex justify-center items-start md:items-center" v-if="content.link">
                <!-- Confirmation checkbox -->
                <input
                    type="checkbox"
                    id="confirmCheck"
                    v-model="isChecked"
                    class="input-checkbox"
                />
                <label for="confirmCheck" class="text-white text-left text-sm md:text-base leading-6">Je confirme avoir redirigé le patient vers un médecin généraliste ou un spécialiste</label>
            </div>
            <a
                v-if="content.link"
                :href="doctorPdfUrl"
                target="_blank"
                class="btn btn--white mt-4"
                :disabled="(!isChecked) ? true : null"
                @click="fileDownloaded"
            >
                Télécharger le PDF
            </a>
            <p v-if="content.caption" class="caption mt-10">
                {{ content.caption }}
            </p>
        </div>
        <extra-advices></extra-advices>
    </div>
</template>

<script>
import ExtraAdvices from "@/app/components/ExtraAdvices";

export default {
    name: "HealthProfessionalPollResult",

    props: {
        doctorPdfUrl: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isChecked : false,
        };
    },

    components: {
        ExtraAdvices,
    },

    methods: {
        restartPoll() {
            this.$emit('restart-poll');
            this.isChecked = false;
        },
        fileDownloaded(){
            this.$emit('file-downloaded');
        }
    }
}
</script>

<style scoped lang="scss">

</style>
