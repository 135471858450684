<template>
    <div class="health-professional-poll background-gradient min-h-screen">
        <template v-if="!currentHealthProfessionalId">
            <login-step
                :site-url="siteUrl"
                @loggedIn="loginHandler"
            >
            </login-step>
        </template>

        <template v-else>
            <health-professional-orientation
                ref="orientation"
                @orientation-done="orientationDone"
                class="health-professional-poll__orientation"
            />
            <stepper
                ref="stepper"
                :steps="steps"
                :restart="restart"
                :init-previous-step-link="true"
                previous-step-text="Étape précédente"
                class="health-professional-poll__stepper hidden opacity-0"
                @stepper-done="stepperDoneHandler"
                @restart-done="restartDoneHandler"
                @previous-step="returnToOrientation"
            >
            </stepper>
            <multi-test-form
                ref="multi"
                class="health-professional-poll__multi-form hidden opacity-0"
                :healthProfessionalId="currentHealthProfessionalId"
                @previous-step="returnToOrientation"
            ></multi-test-form>
            <health-professional-poll-result
                ref="result"
                :content="currentResultContent"
                :doctor-pdf-url="doctorPdfUrl"
                class="health-professional-poll__result hidden opacity-0"
                @restart-poll="restartPollHandler"
                @file-downloaded="updatePollAfterDownload"
            >
            </health-professional-poll-result>

        </template>
    </div>
</template>

<script>
import LoginStep from "@/app/components/LoginStep";
import Stepper from "@/app/components/Stepper";
import HealthProfessionalPollResult from "@/app/components/HealthProfessionalPollResult";
import HealthProfessionalOrientation from "@/app/components/HealthProfessionalOrientation";
import MultiTestForm from "@/app/components/MultiTestForm";
import axios from 'axios';
import gsap from '@lahautesociete/gsap';
import {SplitText} from '@lahautesociete/gsap/SplitText';

gsap.registerPlugin(SplitText);
export default {
    name: "HealthProfessionalPoll",

    props: {
        siteUrl: {
            type: String,
            required: true,
        },
        sectionUid: {
            type: String,
            required: true,
        },
        pollResponseUid: {
            type: String,
            default: null,
        },
        doctorPdfUrl: {
            type: String,
            required: true,
        },
    },

    components: {
        LoginStep,
        Stepper,
        HealthProfessionalPollResult,
        HealthProfessionalOrientation,
        MultiTestForm
    },

    data() {
        return {
            currentHealthProfessionalId: null,
            formValues: null,
            doctorLetterDownloaded: false,
            restart: false,
            submittedResponseUid: this.pollResponseUid, // props are read-only, so we need to copy it to a data property
            resultContent: {
                lowRisk: {
                    title: "<em>Le résultat a une valeur</em><br>peu élévée",
                    text: "La valeur du patient est normale. Pour autant, invitez le à montrer les résultats de ce questionnaire à son médecin pour échanger s’il le souhaite, et à renouveler ce test ultérieurement. Informez le que son mode de vie peut l’aider à diminuer le risque de développer un diabète, vous pouvez en discuter ensemble.",
                    link: true,
                    caption: "Cette action donne accès à un courrier (format PDF) que le patient pourra remettre à son médecin."
                },
                highRisk: {
                    title: "<em>Le résultat a une valeur</em><br>anormalement élévée",
                    text: "Invitez la personne à consulter son médecin dans un délais d'un mois.",
                    link: true,
                    caption: "Cette action donne accès à un courrier (format PDF) que le patient pourra remettre à son médecin."
                },
                notTested: {
                    title: "<em>Le patient n’a pas fait</em><br>de test de glycémie",
                    text: "Invitez le à consulter son médecin s'il le souhaite, et à faire ce test ultérieurement. Informez le que son mode de vie peut l'aider à diminuer le risque de développer un diabète, vous pouvez en discuter ensemble."
                },
            }
        }
    },

    computed: {
        currentResultContent() {
            let key = 'notTested';

            if (this.formValues) {
                this.formValues.forEach((element) => {
                    if (!element[0]) {
                        return;
                    }

                    if (element[0].value === 'Glycémie élevée') {
                        key = 'highRisk';
                    } else if (element[0].value === 'Glycémie peu élevée') {
                        key = 'lowRisk';
                    }
                })
            }
            return this.resultContent[key];
        },

        steps() {
            if (this.pollResponseUid) {
                return [
                    {
                        type: "SingleChoiceStep",
                        overline: "Étape 1 - test",
                        question: "<em>Avez-vous fait</em><br> un test de glycémie capillaire ?",
                        choices: [
                            {
                                name: "fields[pollResponseCapillaryGlucose]",
                                id: "test-1",
                                text: "Oui",
                                value: "Oui",
                            },
                            {
                                name: "fields[pollResponseCapillaryGlucose]",
                                id: "test-2",
                                text: "Non",
                                value: "to-final-step",
                            },
                        ]
                    },
                    {
                        type: "SingleChoiceStep",
                        overline: "étape 2 - résultat du test",
                        question: "<em>Les valeurs sont-elles</em><br> anormalement élevées ?",
                        text: "Si la glycémie capillaire est ≥ à  1,10mg/dl à jeun, ou ≥ à 1,40mg/dl non à jeun la personne est invitée à consulter son médecin traitant. Le médecin traitant met en place la procédure de diagnostic et décidera de la conduite à tenir.",
                        choices: [
                            {
                                name: "fields[pollResponseBloodSugarTestResult]",
                                id: "result-1",
                                text: "Oui",
                                value: "Glycémie élevée",
                            },
                            {
                                name: "fields[pollResponseBloodSugarTestResult]",
                                id: "result-2",
                                text: "Non",
                                value: "Glycémie peu élevée",
                            },
                        ]
                    },
                ];
            }

            return [
                {
                    type: "SingleChoiceStep",
                    overline: "Étape 1 - Test Findrisc",
                    question: "<em>Le patient a-t-il fait</em><br/> un test Findrisc ?",
                    choices: [
                        {
                            name: "fields[pollResponseFindriscTestType]",
                            id: "findrisc-test-type-1",
                            text: "Oui, au format papier",
                            value: 'Format papier',
                        },
                        {
                            name: "fields[pollResponseFindriscTestType]",
                            id: "findrisc-test-type-2",
                            text: "Oui, sur le site",
                            value: 'Sur le site',
                        },
                        {
                            name: "fields[pollResponseFindriscTestType]",
                            id: "findrisc-test-type-3",
                            text: "Non",
                            value: 'to-final-step',
                        }
                    ]
                },
                {
                    type: "SingleChoiceStep",
                    overline: "Étape 2 - test",
                    question: "<em>Avez-vous fait</em><br> un test de glycémie capillaire ?",
                    choices: [
                        {
                            name: "fields[pollResponseCapillaryGlucose]",
                            id: "test-1",
                            text: "Oui",
                            value: "Oui",
                        },
                        {
                            name: "fields[pollResponseCapillaryGlucose]",
                            id: "test-2",
                            text: "Non",
                            value: "to-final-step",
                        },
                    ]
                },
                {
                    type: "SingleChoiceStep",
                    overline: "étape 3 - résultat du test",
                    question: "<em>Les valeurs sont-elles</em><br> anormalement élevées ?",
                    text: "Si la glycémie capillaire est ≥ à  1,10mg/dl à jeun, ou ≥ à 1,40mg/dl non à jeun la personne est invitée à consulter son médecin traitant. Le médecin traitant met en place la procédure de diagnostic et décidera de la conduite à tenir.",
                    choices: [
                        {
                            name: "fields[pollResponseBloodSugarTestResult]",
                            id: "result-1",
                            text: "Oui",
                            value: "Glycémie élevée",
                        },
                        {
                            name: "fields[pollResponseBloodSugarTestResult]",
                            id: "result-2",
                            text: "Non",
                            value: "Glycémie peu élevée",
                        },
                    ]
                },
            ];
        }
    },

    methods: {
        loginHandler(id) {
            this.currentHealthProfessionalId = id;
        },

        orientationDone(event) {
            // Hide the orientation step
            gsap.to(this.$el.querySelector('.health-professional-poll__orientation'), {
                opacity: 0,
                display: 'none',
                duration: 0.3
            });

            // Show solo stepper or multi form
            const next = (event === 1) ? this.$el.querySelector('.health-professional-poll__multi-form') : this.$el.querySelector('.health-professional-poll__stepper');

            gsap.to(next, {
                opacity: 1,
                display: 'block',
                duration: 0.3
            });
        },

        returnToOrientation() {

            gsap.to([
                this.$el.querySelector('.health-professional-poll__stepper'),
                this.$el.querySelector('.health-professional-poll__multi-form'),
                this.$el.querySelector('.health-professional-poll__result'),
            ], {
                opacity: 0,
                display: 'none',
                duration: 0.3
            });

            gsap.to(this.$el.querySelector('.health-professional-poll__orientation'), {
                opacity: 1,
                display: 'block',
                duration: 0.3
            });
        },

        stepperDoneHandler(values) {
            // Save the values
            this.formValues = values;

            // Hide the stepper
            gsap.to(document.querySelector('.health-professional-poll__stepper'), {
                opacity: 0,
                display: 'none',
                duration: 0.3
            });

            // Show the result
            gsap.to(document.querySelector('.health-professional-poll__result'), {
                opacity: 1,
                display: 'block',
                duration: 0.3,
                delay: 0.3
            });

            this.submitPoll();
        },

        submitPoll() {
            const payload = new FormData();

            payload.append('CRAFT_CSRF_TOKEN', window.Craft.csrfTokenValue);
            payload.append('action', 'guest-entries/save');
            payload.append('sectionUid', this.sectionUid);
            payload.append('fields[pollResponseHealthProfessional][]', this.currentHealthProfessionalId);


            if (this.pollResponseUid) {
                payload.append('entryUid', this.pollResponseUid);
                payload.append('fields[pollResponseFindriscTestType]', 'Sur le site');
            }

            this.formValues.forEach((pItem) => {
                payload.append(pItem[0].name, pItem[0].value);
            });

            axios.post(document.location.href, payload, {
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                // update the submitted response uid for doctor letter download
                if (response.data.entry && response.data.entry.uid) {
                    this.submittedResponseUid = response.data.entry.uid;
                }
            }).catch((error) => {
                console.error(error);
            });
        },
        updatePollAfterDownload() {
                const payload = new FormData();
                payload.append('CRAFT_CSRF_TOKEN', window.Craft.csrfTokenValue);
                payload.append('action', 'guest-entries/save');
                payload.append('sectionUid', this.sectionUid);
                payload.append('entryUid', this.submittedResponseUid);
                payload.append('fields[pollResponseDoctorLetterDownloaded]', '1');

                axios.post(document.location.href, payload, {
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).catch((error) => {
                    console.error("Erreur lors de la mise à jour:", error.response);
                });
        },
        restartPollHandler() {
            this.formValues = [];
            this.doctorLetterDownloaded = false;

            // Show the stepper
            gsap.to(this.$el.querySelector('.health-professional-poll__stepper'), {
                opacity: 1,
                display: 'block',
                duration: 0.3
            });

            // Hide the results
            gsap.to(this.$el.querySelector('.health-professional-poll__result'), {
                opacity: 0,
                display: 'none',
                duration: 0.3,
                delay: 0.3
            });

            this.restart = true;
        },

        restartDoneHandler() {
            this.restart = false;
        },
    },
}
</script>
